import React, { ReactNode } from 'react';
import { Row, Col, Dropdown } from 'react-bootstrap';
import Pagination from './Pagination';

interface FooterProps {
  limit: number;
  offset: number;
  totalCount: number;
  onPageChanged: (limit: number, offset: number) => any;
}

function TableFooter({ limit, offset, totalCount, onPageChanged }: FooterProps) {
  const onLimitValueSelected = (limit: number) => {
    onPageChanged(limit, 0);
  };

  return (
    <Row>
      <Col>
        <span>Rows per page</span>
        <Dropdown>
          <Dropdown.Toggle>{limit}</Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => onLimitValueSelected(2)}>2</Dropdown.Item>
            <Dropdown.Item onClick={() => onLimitValueSelected(5)}>5</Dropdown.Item>
            <Dropdown.Item onClick={() => onLimitValueSelected(10)}>10</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      <Col>
        <Pagination limit={limit} offset={offset} totalCount={totalCount} onPageChanged={onPageChanged} />
      </Col>
    </Row>
  );
}

export default TableFooter;
