import api from 'api';
import PageContainer from 'components/common/PageContainer';
import SelectableDropdown from 'components/common/SelectableDropdown';
import FormField from 'components/form/FormField';
import { Form, Formik } from 'formik';
import { useMemo, useState } from 'react';
import { Card, Container, Dropdown } from 'react-bootstrap';
import * as Yup from 'yup';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import urlUtils from 'utilities/urlUtils';
import { LocationBodyVM } from 'models/locations';
import { LocationTimeslotType } from 'models/timeslots';
import LocationTimeSlots from './LocationTimeSlots';

export const FAKE_NEW_ID = 'new';

export default () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getInitFormData = () => {
    return {
      city: '',
      postalCode: '',
      province: '',
      key: '',
    };
  };

  const isNew = useMemo(() => id == null || id == FAKE_NEW_ID, [id])

  const [formData, setFormData] = useState<any>(getInitFormData());

  useEffect(() => {
    if (!isNew) {
      api.locations.getDetail(id!).then((res) => {
        setFormData({
          city: res.city,
          postalCode: res.postalCode,
          province: res.province,
          key: res.key,
        });
      });
    } else {
      setFormData(getInitFormData());
    }
  }, [id]);

  const validationSchema = Yup.object().shape({
    // oldPassword: Yup.string().required('Vecchia password richiesta'),
    // newPassword: Yup.string().required('Nuova password richiesta'),
    // newPassword2: Yup.string()
    //   .required('Conferma nuova password richiesta')
    //   .test('check-new-passwords', '', function (value, ctx) {
    //     if (value && value !== ctx.parent.newPassword) {
    //       return this.createError({ message: `Le nuove password non coincidono` });
    //     }
    //     return true;
    //   }),
    // phone: Yup.string().required("Telefono richiesto"),
  });


  return (
    <PageContainer>
      <Card>
        <Card.Body>
          <Formik
            initialValues={formData}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values) => {
              setLoading(true);

              const conv: LocationBodyVM = {
                city: values.city,
                province: values.province,
                postalCode: values.postalCode,
              };

              console.log('api.locations', id);
              let prom;
              if (!isNew) {
                prom = api.locations.edit(id!, conv);
              } else {
                prom = api.locations.add(conv);
              }

              prom
                .then(() => {
                  navigate(urlUtils.locations.main());
                  // dispatch({
                  //   type: AuthContext.ACTIONS.SET_USER_PROFILE,
                  //   value: user,
                  // });
                  // TODO: Aggiungere messaggio di conferma cambio password
                })
                .finally(() => setLoading(false));
              /* showMessageBox({
                //icon: MessageBoxIcon.ALERT,
                title: 'Operazione effettuata con successo',
                type: MessageBoxButtonsType.YESNO,
                onOk() {
                  console.log('Submit change password');
                  setLoading(true);
                  api.user
                    .changePassword(values.oldPassword, values.newPassword)
                    .then((user) => {
                      // dispatch({
                      //   type: AuthContext.ACTIONS.SET_USER_PROFILE,
                      //   value: user,
                      // });
                      // TODO: Aggiungere messaggio di conferma cambio password
                    })
                    .finally(() => setLoading(false));
                },
              }); */
            }}
          >
            {(formik) => {
              const { errors, touched, isValid, dirty, handleChange, handleBlur, handleSubmit } = formik;
              return (
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <div className="row">
                    <div className="col-12">
                      <div className="d-flex flex-column gap-custom mb-3 gSpace-1875">
                        <div className="shadow-box p-3">
                          <div className="d-flex flex-column gap-2">
                            <FormField
                              name="city"
                              label="Città"
                              type="text"
                              // disabled={id != null && id != FAKE_NEW_ID}
                              errors={errors}
                              touched={touched}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <FormField
                              name="postalCode"
                              label="CAP"
                              type="text"
                              // disabled={id != null && id != FAKE_NEW_ID}
                              errors={errors}
                              touched={touched}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <FormField
                              name="province"
                              label="Provincia"
                              type="text"
                              // disabled={id != null && id != FAKE_NEW_ID}
                              errors={errors}
                              touched={touched}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {(!isNew) && <FormField
                              name="key"
                              label="UID"
                              type="text"
                              disabled={true}
                              errors={errors}
                              touched={touched}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />}
                          </div>
                        </div>
                      </div>
                      <div className="mt-auto">
                        <button disabled={!(dirty && isValid) || loading} className="btn btn-primary btn-full" onChange={() => handleSubmit()}>
                          Salva
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>

          {!isNew && <LocationTimeSlots locationId={id!} />}
        </Card.Body>
      </Card>
    </PageContainer>
  );
};
