import client, { downloadFile } from 'api/client';
import { BaseFullTableRequest, BasePaginatedData } from 'models/common';
import { CouponBodyVM, CouponPaginatedRequest, CouponType } from 'models/coupons';

export function get(pq: CouponPaginatedRequest): Promise<BasePaginatedData<CouponType>> {
  return client
    .post(`coupons/get`, pq)
    .then((result: any) => {
      return result;
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}

export function getDetail(id: string): Promise<CouponType> {
  return client
    .get(`coupons/${id}`)
    .then((result: any) => {
      return result;
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}

export function add(pq: CouponBodyVM): Promise<CouponType> {
  return client
    .post(`coupons`, pq)
    .then((result: any) => {
      return result;
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}

export function edit(id: string, pq: CouponBodyVM): Promise<CouponType> {
  return client
    .put(`coupons/${id}`, pq)
    .then((result: any) => {
      return result;
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}

export function remove(id: string): Promise<void> {
  return client
    .delete(`coupons/${id}`)
    .then(() => {
      console.log('coupon rimosso');
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}
