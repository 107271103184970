import api from 'api';
import ConfirmModal from 'components/common/ConfirmModal';
import PageContainer from 'components/common/PageContainer';
import SelectableDropdown from 'components/common/SelectableDropdown';
import ServerSideTable from 'components/table/ServerSideTable';
import TableButton from 'components/table/TableButton';
import { ORDER_STATUS, ORDER_STATUS_LABEL } from 'constants/orders';
import { BaseFullTableRequest, ListElement } from 'models/common';
import { CouponType } from 'models/coupons';
import { OrderCmp } from 'models/orders';
import { TableColumnType, TableMassiveActionsType } from 'models/table';
import { useState } from 'react';
import { Button, Card, Container, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getValueLabel } from 'utilities/functionUtils';
import { getListElementArray } from 'utilities/listUtils';
import urlUtils from 'utilities/urlUtils';
import { FAKE_NEW_ID } from './CouponDetailPage';
import { getCouponPropertyDiscountString } from 'utilities/couponUtilities';

export default () => {
  const [forceFetchData, setForceFetchData] = useState<boolean>(false);

  const navigate = useNavigate();

  const onDeleteCoupon = (id: string | null | undefined) => {
    if (id != null) {
      api.coupons.remove(id).then(() => {
        setForceFetchData(true);
      });
    }
  };

  const tableColumns: TableColumnType<CouponType>[] = [
    {
      title: 'Codice',
      render: (row) => row.code,
    },
    {
      id: 'Abilitato',
      title: 'Stato',
      render: (row) => (row.enabled ? 'Si' : 'No'),
    },
    /* {
      title: 'Abilitazione temporale',
      render: (row) => `Da ${row.from ?? '-'} a ${row.from ?? '-'}`,
    }, */
    {
      title: 'Sconto',
      render: (row) => (row.discounts.length > 0 ? getCouponPropertyDiscountString(row.discounts[0]) : '-'),
    },
    {
      title: '',
      render: (row) => (
        <>
          <TableButton tooltipText="Modifica" iconClass="bi bi-pencil" onClick={() => navigate(urlUtils.coupons.detail(row.id))} />
          <TableButton tooltipText="Elimina" iconClass="bi bi-trash" onClick={() => onDeleteCoupon(row.id)} />
        </>
      ),
    },
  ];

  const massiveActions: TableMassiveActionsType[] = [
    /* {
      label: 'Download ordini',
      fnc: (req: BaseFullTableRequest) => api.orders.exportOrdersDetails(req),
    }, */
  ];

  return (
    <PageContainer>
      <Card>
        <Card.Body>
          <ServerSideTable
            search={true}
            columns={tableColumns}
            fetchApi={api.coupons.get}
            forceFetchData={forceFetchData}
            onForceFetchDataCompleted={() => setForceFetchData(false)}
            massiveActions={massiveActions}
          />
          <Button onClick={() => navigate(urlUtils.coupons.detail(FAKE_NEW_ID))}>Aggiungi nuovo coupon</Button>
        </Card.Body>
      </Card>
      {/* <ConfirmModal
        show={statusChangeOrder != null && statusChangeValue != null}
        title="Modifica stato ordine"
        content={`Sei sicuro di voler modificare lo stato dell'ordine "${statusChangeOrder?.number ?? ''}" con lo stato "${getValueLabel(
          statusChangeValue,
          ORDER_STATUS_LABEL,
        )}"?`}
        onOk={() => onStatusChangedConfirm(statusChangeOrder, statusChangeValue)}
        onCancel={onStatusChangedCancel}
      /> */}
    </PageContainer>
  );
};
