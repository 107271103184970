import React, { ReactNode } from 'react';
import { Button } from 'react-bootstrap';

interface ButtonProps {
  iconClass: string;
  onClick: () => any;
  tooltipText: string;
  isEnabled?: boolean;
}

function TableButton({ iconClass, onClick, tooltipText, isEnabled = true }: ButtonProps) {
  return (
    <Button
      style={{ cursor: isEnabled ? 'pointer' : 'not-allowed' }}
      className={`${iconClass} table-button`}
      onClick={() => (isEnabled ? onClick() : undefined)}
      title={tooltipText}
    ></Button>
  );
}

export default TableButton;
