import React, { Component } from 'react';
import MainMenu from './MainMenu';
import SiderMenuLogo from './SiderMenuLogo';
import useAppStore from 'store/appStore';

type PropsType = React.HTMLAttributes<HTMLElement>;

export default ({ className, ...rest }: PropsType) => {
  const { isMenuCollapsed, setMenuStatus } = useAppStore();
  return (
    <div className={`sider-menu ${isMenuCollapsed ? 'collapsed' : ''}`}>
      <SiderMenuLogo />
      {isMenuCollapsed ? (
        <div className="exandable-icon-menu">
          <i className="bi bi-chevron-bar-right " onClick={() => setMenuStatus(false)}></i>
        </div>
      ) : null}
      <MainMenu />
    </div>
  );
};
