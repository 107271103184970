import api from 'api';
import ConfirmModal from 'components/common/ConfirmModal';
import PageContainer from 'components/common/PageContainer';
import SelectableDropdown from 'components/common/SelectableDropdown';
import ServerSideTable from 'components/table/ServerSideTable';
import TableButton from 'components/table/TableButton';
import { ORDER_STATUS, ORDER_STATUS_LABEL, ORDER_WAREHOUSE_STATUS, ORDER_WAREHOUSE_STATUS_LABEL } from 'constants/orders';
import { BaseFullTableRequest, ListElement } from 'models/common';
import { OrderCmp } from 'models/orders';
import { TableColumnType, TableMassiveActionsType } from 'models/table';
import { useState } from 'react';
import { Card, Container, Dropdown } from 'react-bootstrap';
import { getValueLabel } from 'utilities/functionUtils';
import { getListElementArray } from 'utilities/listUtils';

export default () => {
  const [forceFetchData, setForceFetchData] = useState<boolean>(false);
  const [statusChangeOrder, setStatusChangeOrder] = useState<OrderCmp | undefined>();
  const [statusChangeValue, setStatusChangeValue] = useState<ORDER_STATUS | undefined>();
  
  const onStatusChanged = (order: OrderCmp, status: ORDER_STATUS) => {
    setStatusChangeOrder(order);
    setStatusChangeValue(status);
  };
  const onStatusChangedConfirm = (order: OrderCmp | undefined, status: ORDER_STATUS | undefined) => {
    if (order != null && status != null) {
      // TODO: Gestire il cambio stato
      api.orders.updateStatus(order.id, status).then((res) => {
        setForceFetchData(true);
      });
    }
    setStatusChangeOrder(undefined);
    setStatusChangeValue(undefined);
  };
  const onStatusChangedCancel = () => {
    setStatusChangeOrder(undefined);
    setStatusChangeValue(undefined);
  };

  const [warehouseStatusChangeOrder, setWarehouseStatusChangeOrder] = useState<OrderCmp | undefined>();
  const [warehouseStatusChangeValue, setWarehouseStatusChangeValue] = useState<ORDER_WAREHOUSE_STATUS | undefined>();

  const onWarehouseStatusChanged = (order: OrderCmp, status: ORDER_WAREHOUSE_STATUS) => {
    setWarehouseStatusChangeOrder(order);
    setWarehouseStatusChangeValue(status);
  };
  const onWarehouseStatusChangedConfirm = (order: OrderCmp | undefined, status: ORDER_WAREHOUSE_STATUS | undefined) => {
    if (order != null && status != null) {
      // TODO: Gestire il cambio stato
      api.orders.updateWarehouseStatus(order.id, status).then((res) => {
        setForceFetchData(true);
      });
    }
    setWarehouseStatusChangeOrder(undefined);
    setWarehouseStatusChangeValue(undefined);
  };

  const onWarehouseStatusChangedCancel = () => {
    setWarehouseStatusChangeOrder(undefined);
    setWarehouseStatusChangeValue(undefined);
  };

  const onExportOrderDetail = (id: string) => {
    api.orders.downloadOrderDetails(id);
  };

  const tableColumns: TableColumnType<OrderCmp>[] = [
    {
      title: 'Ordine',
      render: (row) => row.number,
    },
    {
      id: 'warehouseStatus',
      title: 'Stato',
      render: (row) => <SelectableDropdown value={row.warehouseStatus} labels={ORDER_WAREHOUSE_STATUS_LABEL} onChange={(v) => onWarehouseStatusChangedConfirm(row, v as ORDER_WAREHOUSE_STATUS)} />,
      filterData: getListElementArray(ORDER_WAREHOUSE_STATUS, ORDER_WAREHOUSE_STATUS_LABEL),
      onFilterChange: (values) => console.log('values', values),
    },
    {
      title: 'Indirizzo di spedizione',
      render: (row) => row.shippingAddress,
    },
    {
      id: 'status',
      title: 'Stato utente',
      render: (row) => <SelectableDropdown value={row.status} labels={ORDER_STATUS_LABEL} onChange={(v) => onStatusChanged(row, v as ORDER_STATUS)} />,
      filterData: getListElementArray(ORDER_STATUS, ORDER_STATUS_LABEL),
      onFilterChange: (values) => console.log('values', values),
    },
    {
      title: '',
      render: (row) => <TableButton tooltipText="Scarica dettaglio ordini" iconClass="bi bi-file-earmark-excel" onClick={() => onExportOrderDetail(row.id)} />,
    },
  ];

  const massiveActions: TableMassiveActionsType[] = [
    {
      label: 'Download ordini',
      fnc: (req: BaseFullTableRequest) => api.orders.exportOrdersDetails(req),
    },
  ];

  return (
    <PageContainer>
      <Card>
        <Card.Body>
          <ServerSideTable
            search={true}
            columns={tableColumns}
            fetchApi={api.orders.get}
            forceFetchData={forceFetchData}
            onForceFetchDataCompleted={() => setForceFetchData(false)}
            massiveActions={massiveActions}
          />
        </Card.Body>
      </Card>
      <ConfirmModal
        show={statusChangeOrder != null && statusChangeValue != null}
        title="Modifica stato ordine"
        content={`Sei sicuro di voler modificare lo stato dell'ordine "${statusChangeOrder?.number ?? ''}" con lo stato "${getValueLabel(
          statusChangeValue,
          ORDER_STATUS_LABEL,
        )}"?`}
        onOk={() => onStatusChangedConfirm(statusChangeOrder, statusChangeValue)}
        onCancel={onStatusChangedCancel}
      />
      <ConfirmModal
        show={warehouseStatusChangeOrder != null && warehouseStatusChangeValue != null}
        title="Modifica stato ordine"
        content={`Sei sicuro di voler modificare lo stato dell'ordine "${warehouseStatusChangeOrder?.number ?? ''}" con lo stato "${getValueLabel(
          warehouseStatusChangeValue,
          ORDER_WAREHOUSE_STATUS_LABEL,
        )}"?`}
        onOk={() => onWarehouseStatusChangedConfirm(warehouseStatusChangeOrder, warehouseStatusChangeValue)}
        onCancel={onWarehouseStatusChangedCancel}
      />
    </PageContainer>
  );
};
