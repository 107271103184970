import api from 'api';
import PageContainer from 'components/common/PageContainer';
import SelectableDropdown from 'components/common/SelectableDropdown';
import FormField from 'components/form/FormField';
import { ITEM_CATEGORY_LABEL } from 'constants/items';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Card, Container, Dropdown } from 'react-bootstrap';
import * as Yup from 'yup';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import urlUtils from 'utilities/urlUtils';
import { CmpItemDetail, ItemDetailBodyVM } from 'models/Items';

export const FAKE_NEW_ID = 'new';

export default () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getInitFormData = () => {
    return {
      id: '',
      name: '',
      info: '',
      img: '',
      isMultiple: null,
      isVAR: null,
    };
  };

  const [formData, setFormData] = useState<any>(getInitFormData());

  useEffect(() => {
    if (id != null && id != FAKE_NEW_ID) {
      api.items.getSingleItemDetail(id).then((res) => {
        setFormData({
          name: res.name,
          info: res.info,
          img: res.img,
          isMultiple: res.isMultiple,
          isVAR: res.isVAR,
          itemId: res.itemId,
        });
      });
    } else {
      setFormData(getInitFormData());
    }
  }, [id]);

  const validationSchema = Yup.object().shape({
    // oldPassword: Yup.string().required('Vecchia password richiesta'),
    // newPassword: Yup.string().required('Nuova password richiesta'),
    // newPassword2: Yup.string()
    //   .required('Conferma nuova password richiesta')
    //   .test('check-new-passwords', '', function (value, ctx) {
    //     if (value && value !== ctx.parent.newPassword) {
    //       return this.createError({ message: `Le nuove password non coincidono` });
    //     }
    //     return true;
    //   }),
    // phone: Yup.string().required("Telefono richiesto"),
  });

  return (
    <PageContainer>
      <Card>
        <Card.Body>
          <Formik
            initialValues={formData}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values) => {
              setLoading(true);

              const conv: ItemDetailBodyVM = {
                name: values.name,
                info: values.info,
                img: values.img,
                isMultiple: values.isMultiple,
                isVAR: values.isVAR,
                itemId: values.itemId,
              };
              console.log('item...id...: ', values.itemId);
              let prom;
              if (id != null && id != FAKE_NEW_ID) {
                prom = api.items.editDetail(id, conv);
              } else {
                return;
              }

              prom
                .then(() => {
                  navigate(urlUtils.items.details(values.itemId));
                  // dispatch({
                  //   type: AuthContext.ACTIONS.SET_USER_PROFILE,
                  //   value: user,
                  // });
                  // TODO: Aggiungere messaggio di conferma cambio password
                })
                .finally(() => setLoading(false));
              /* showMessageBox({
                //icon: MessageBoxIcon.ALERT,
                title: 'Operazione effettuata con successo',
                type: MessageBoxButtonsType.YESNO,
                onOk() {
                  console.log('Submit change password');
                  setLoading(true);
                  api.user
                    .changePassword(values.oldPassword, values.newPassword)
                    .then((user) => {
                      // dispatch({
                      //   type: AuthContext.ACTIONS.SET_USER_PROFILE,
                      //   value: user,
                      // });
                      // TODO: Aggiungere messaggio di conferma cambio password
                    })
                    .finally(() => setLoading(false));
                },
              }); */
            }}
          >
            {(formik) => {
              const { errors, touched, isValid, dirty, handleChange, handleBlur, handleSubmit } = formik;
              return (
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <div className="row">
                    <div className="col-12">
                      <div className="d-flex flex-column gap-custom mb-3 gSpace-1875">
                        <div className="shadow-box p-3">
                          <div className="d-flex flex-column gap-2">
                            <FormField name="name" label="Nome" type="text" errors={errors} touched={touched} onChange={handleChange} onBlur={handleBlur} />

                            <FormField name="info" label="Info" type="text" errors={errors} touched={touched} onChange={handleChange} onBlur={handleBlur} />

                            <FormField name="image" label="Image" type="text" errors={errors} touched={touched} onChange={handleChange} onBlur={handleBlur} />

                            <FormField
                              name="isMultiple"
                              label="Multiplo"
                              type="checkbox"
                              errors={errors}
                              touched={touched}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <FormField name="isVAR" label="VAR" type="checkbox" errors={errors} touched={touched} onChange={handleChange} onBlur={handleBlur} />
                          </div>
                        </div>
                      </div>
                      <div className="mt-auto">
                        <button disabled={!(dirty && isValid) || loading} className="btn btn-primary btn-full" onChange={() => handleSubmit()}>
                          Salva
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Card.Body>
      </Card>
    </PageContainer>
  );
};
