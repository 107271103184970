import PageContainer from 'components/common/PageContainer';
import { Button, Card, Col, Row } from 'react-bootstrap';

export default () => {
  const onExportOrders = () => {
    // api.orders.();
  };

  return (
    <PageContainer>
      <Card title="Dev tools">
        <div>DevTools</div>

        <Row>
          <Col>
            <Button onClick={onExportOrders}>Export orders</Button>
          </Col>
        </Row>
      </Card>
    </PageContainer>
  );
};
