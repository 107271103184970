import * as auth from './auth';
import * as items from './items';
import * as orders from './orders';
import * as coupons from './coupons';
import * as locations from './locations';

export default {
  auth,
  items,
  orders,
  coupons,
  locations,
};
