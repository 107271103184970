import Header from 'components/layout/Header';
import SiderMenu from 'components/menu/SiderMenu';
import React, { ReactNode } from 'react';
// import AuthContext from '../../context/authContext';

interface PropsType extends React.HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
}

export default ({ children }: PropsType) => {
  // const { state } = React.useContext(AuthContext.store);
  const siderOpen = true;
  const showSiderMenu = siderOpen ? 'my-sider-menu' : 'my-sider-menu-none';
  return (
    <div className="main-layout-container d-flex ">
      <SiderMenu className={showSiderMenu} />
      <div className="flex-fill d-flex flex-column ">
        <Header />
        <main className="flex-fill">{children}</main>
      </div>
    </div>
  );
};
