import { Dropdown } from 'react-bootstrap';
import { getValueLabel } from 'utilities/functionUtils';

type PropsType = {
  value: string;
  labels: Record<string, string>;
  onChange: (v: string) => void;
};

export default ({ value, labels, onChange }: PropsType) => {
  return (
    <Dropdown>
      <Dropdown.Toggle>{getValueLabel(value, labels)}</Dropdown.Toggle>

      <Dropdown.Menu>
        {Object.keys(labels).map((v) => (
          <Dropdown.Item key={v} onClick={() => onChange(v)}>
            {getValueLabel(v, labels)}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
