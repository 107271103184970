export default {
  login: () => '/login',
  main: () => '/home',
  orders: {
    main: () => '/orders',
  },
  items: {
    main: () => '/items',
    edit: (id = ':id') => `/items/edit/${id}`,
    details: (id = ':id') => `/items/details/${id}`,
    editDetails: (id = ':id') => `/items/details/edit/${id}`,
  },
  coupons: {
    main: () => '/coupons',
    detail: (id = ':id') => `/coupons/details/${id}`,
  },
  locations: {
    main: () => '/locations',
    detail: (id = ':id') => `/locations/details/${id}`,
  },
  devtools: { main: () => '/devtools', items: () => '/devtools/items', orders: () => '/devtools/orders' },
  settings: { main: () => '/settings' },
  page1: { main: () => '/page-1' },
  page2: { main: () => '/page-2' },
  page3: { main: () => '/page-3' },
};
