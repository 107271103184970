import client, { downloadFile } from 'api/client';
import { BasePaginatedData } from 'models/common';
import { ItemCmp, ItemPaginatedRequest, ItemBodyVM, CmpItemDetail, ItemDetailBodyVM } from 'models/Items';

export function importItems(fileName: string, fileBase64: string): Promise<void> {
  return client
    .post(`items/import`, { fileName: fileName, fileContent: fileBase64 })
    .then((result: any) => {
      return;
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}

export function importItemsPrice(fileName: string, fileBase64: string): Promise<void> {
  return client
    .post(`items/prices/import`, { fileName: fileName, fileContent: fileBase64 })
    .then((result: any) => {
      return;
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}

export function downloadItemsTemplate(): Promise<void> {
  return client
    .post(`items/template/export`)
    .then((result: any) => {
      downloadFile(result.fileName, result.fileContent);
      return;
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}

export function downloadItems(): Promise<void> {
  return client
    .post(`items/export`)
    .then((result: any) => {
      downloadFile(result.fileName, result.fileContent);
      return;
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}

export function getItems(pq: ItemPaginatedRequest): Promise<BasePaginatedData<ItemCmp>> {
  return client
    .post(`items/items`, pq)
    .then((result: any) => {
      return result;
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}

export function edit(id: string, pq: ItemBodyVM): Promise<ItemCmp> {
  return client
    .put(`items/${id}`, pq)
    .then((result: any) => {
      return result;
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}

export function editDetail(id: string, pq: ItemDetailBodyVM): Promise<CmpItemDetail> {
  return client
    .put(`items/itemdetails/${id}`, pq)
    .then((result: any) => {
      return result;
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}

export function getSingleItem(id: string): Promise<ItemCmp> {
  return client
    .get(`items/${id}`)
    .then((result: any) => {
      return result;
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}

export function remove(id: string): Promise<void> {
  return client
    .delete(`items/${id}`)
    .then(() => {
      console.log('rimosso item e i suoi details');
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}

export function getItemDetails(pq: ItemPaginatedRequest): Promise<BasePaginatedData<CmpItemDetail>> {
  return client
    .post(`items/itemdetails`, pq)
    .then((result: any) => {
      return result;
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}

export function getSingleItemDetail(id: string): Promise<CmpItemDetail> {
  return client
    .get(`items/itemdetails/single/${id}`)
    .then((result: any) => {
      return result;
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}

export function removeItemDetail(id: string): Promise<void> {
  return client
    .delete(`items/itemdetails/${id}`)
    .then(() => {
      console.log('rimosso item e i suoi details');
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}