export enum ITEM_CATEGORY {
  Water = 'water',
  Drink = 'drink',
  Juice = 'juice',
  Beer = 'beer',
  Wine = 'wine',
}
export const ITEM_CATEGORY_LABEL = {
  [ITEM_CATEGORY.Water]: 'Water',
  [ITEM_CATEGORY.Drink]: 'Drink',
  [ITEM_CATEGORY.Juice]: 'Juice',
  [ITEM_CATEGORY.Beer]: 'Beer',
  [ITEM_CATEGORY.Wine]: 'Wine',
};
