import useAppStore from 'store/appStore';
import { getPublicImageUrl } from 'utilities/imageUtils';

export default () => {
  const { isMenuCollapsed, setMenuStatus } = useAppStore();
  return (
    <div className="sider-menu-logo-container">
      <div className="sider-menu-logo">
        {isMenuCollapsed ? (
          <img className="sider-menu-logo-short" src={getPublicImageUrl('menu-logo-short.png')} />
        ) : (
          <img className="sider-menu-logo-full" src={getPublicImageUrl('menu-logo-full.png')} />
        )}
      </div>
      {isMenuCollapsed ? null : (
        <div className="sider-menu-btn">
          <i className="bi bi-list " onClick={() => setMenuStatus(true)}></i>
        </div>
      )}
    </div>
  );
};
