/* eslint-disable no-constant-condition */
import { isInaccessible } from '@testing-library/react';
import React, { useState } from 'react';
import TableColumnFilter from './tableColumnActions/TableColumnFilter';
import { ListElement } from 'models/common';
import { CheckboxDropdownItemType } from 'components/common/CheckboxDropdownItems';
import { TableColumnType } from 'models/table';

interface ColumnsProps<T> {
  columns: TableColumnType<T>[];
}
interface ColumnProps<T> {
  column: TableColumnType<T>;
}

function TableColumn<T>({ column }: ColumnProps<T>) {
  return (
    <th>
      <span>
        <span className="th-title">{column.title}</span>
        <span className="th-actions">
          {column.filterData != null && column.filterData.length > 0 && column.onFilterChange != null ? (
            <span className="th-action">
              <TableColumnFilter items={column.filterData} onChange={column.onFilterChange} />
            </span>
          ) : null}
        </span>
      </span>
    </th>
  );
}

function TableColumns<T>({ columns }: ColumnsProps<T>) {
  const titles = [];

  for (let i = 0; i < columns.length; i++) {
    titles.push(<TableColumn key={i} column={columns[i]} />);
  }

  return <tr>{titles}</tr>;
}

export default TableColumns;
