import * as React from 'react';
import { Button as BootstrapButton, Spinner, ButtonProps } from 'react-bootstrap';

interface Props extends ButtonProps {
  loading?: boolean;
}

const Button: React.FC<Props> = ({ loading, children, disabled, ...rest }) => {
  let loadingContent = null;
  if (loading) {
    loadingContent = (
      <Spinner
        className="me-3 align-text-bottom"
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    );
  }
  return (
    <BootstrapButton {...rest} disabled={disabled || loading}>
      {loadingContent}
      {children}
    </BootstrapButton>
  );
};

export default Button;
