import axios from 'axios';
import useAuthStore from 'store/authStore';
import urlUtils from 'utilities/urlUtils';

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // timeout: 1000
});

client.interceptors.request.use(
  async function (options) {
    const jwtToken = useAuthStore.getState().token;
    if (jwtToken != null) {
      options.headers!['authorization'] = `Bearer ${jwtToken}`;
    } else {
      delete options.headers!['authorization'];
    }
    return options;
  },
  function (error) {
    console.log('Request error: ', error);
    return Promise.reject(error);
  },
);

client.interceptors.response.use(
  async function (response) {
    // TODO: Gestire errori globali
    return response.data;
  },
  function (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      useAuthStore.getState().clearUser();
      location.href = urlUtils.login();
    }
    console.log('Request error: ', error);
    return Promise.reject(error);
  },
);

export default client;

export const downloadFile = (fileName: string, fileContent: string) => {
  const a = document.createElement('a'); //Create <a>
  a.href = fileContent; //Image Base64 Goes here
  a.download = fileName; //File name Here
  a.click(); //Downloaded file
};
