import PageContainer from 'components/common/PageContainer';
import { Card, ListGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import urlUtils from 'utilities/urlUtils';

export default () => {
  const navigate = useNavigate();
  return (
    <PageContainer>
      <Card title="Dev tools">
        <ListGroup variant="flush">
          <ListGroup.Item action onClick={() => navigate(urlUtils.devtools.items())}>
            Job 1 <i className="bi bi-arrow-right-short"></i>
          </ListGroup.Item>
          <ListGroup.Item action onClick={() => navigate(urlUtils.devtools.orders())}>
            Job 2 <i className="bi bi-arrow-right-short"></i>
          </ListGroup.Item>
        </ListGroup>
      </Card>
    </PageContainer>
  );
};
