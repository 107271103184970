import api from 'api';
import PageContainer from 'components/common/PageContainer';
import UploadButton from 'components/common/UploadButton';
import { Button, Card, Row, Col } from 'react-bootstrap';
import { fileToBase64 } from 'utilities/functionUtils';

export default () => {
  const onExportItemTemplate = () => {
    api.items.downloadItemsTemplate();
  };

  const onImportItems = (files: File[]) => {
    const f = files[0];
    fileToBase64(f).then((b64) => {
      api.items.importItems(f.name, b64);
    });
  };

  const onExportItem = () => {
    api.items.downloadItems();
  };

  const onImportItemsPrice = (files: File[]) => {
    const f = files[0];
    fileToBase64(f).then((b64) => {
      api.items.importItemsPrice(f.name, b64);
    });
  };
  return (
    <PageContainer>
      <Card>
        <Row>
          <Col>
            <Button onClick={onExportItemTemplate}>Export item template to CSV</Button>
          </Col>
        </Row>
      </Card>
      <Card>
        <div>
          Import items from CSV:
          <UploadButton onConfirm={onImportItems} />
        </div>
      </Card>
      <Card>
        <Row>
          <Col>
            <Button onClick={onExportItem}>Export item to CSV</Button>
          </Col>
        </Row>
      </Card>
      <Card>
        <div>
          Import items price from CSV:
          <UploadButton onConfirm={onImportItemsPrice} />
        </div>
      </Card>
    </PageContainer>
  );
};
