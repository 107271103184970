import api from 'api';
import { BaseFullTableRequest, BasePaginatedData, BasePaginatedRequest, ExtraPaginatedRequestFields } from 'models/common';
import { PaginationElementType, TableColumnType, TableMassiveActionsType } from 'models/table';
import { useEffect, useState } from 'react';
import Table from './Table';
import { Dropdown, DropdownButton, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

interface ServerSideTableProps<T> {
  columns: TableColumnType<T>[];
  rowClickHandler?: ((row: T, rIdx: number) => void) | undefined;
  fetchApi: (data: any) => Promise<BasePaginatedData<T>>;
  customPaginatedRequest?: any;
  key?: string;
  forceFetchData?: boolean;
  onForceFetchDataCompleted?: () => any;
  search?: boolean;
  massiveActions?: TableMassiveActionsType[];
}

function ServerSideTable<T = any>({
  columns,
  rowClickHandler = undefined,
  fetchApi,
  customPaginatedRequest = undefined,
  key,
  forceFetchData = false,
  onForceFetchDataCompleted = undefined,
  search = false,
  massiveActions,
}: ServerSideTableProps<T>) {
  const { id } = useParams();
  const [tableFilters, setTableFilters] = useState<any>({});
  const [tableRows, setTableRows] = useState<T[]>([]);
  const [tableTotalRows, setTableTotalRows] = useState<number>(0);
  const [tablePage, setTablePage] = useState<PaginationElementType>({ limit: 5, offset: 0, q: '' });

  useEffect(() => {
    console.log('fetchApi', tablePage);

    const request: BasePaginatedRequest = {
      pageSize: tablePage.limit,
      page: tablePage.offset == 0 ? 0 : Math.floor(tablePage.offset / tablePage.limit),
      ...getFullTableRequest(),
      ...addExtraFieldRequest(),
    };
    console.log('richiesta: ', request);
    fetchApi(customPaginatedRequest === undefined ? request : customPaginatedRequest)
      .then((res) => {
        console.log('SSR Table', res);
        console.log('id che arriva alla tabella', id);
        setTableRows(res.content);
        setTableTotalRows(res.totalCount);
      })
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .finally(() => forceFetchData && onForceFetchDataCompleted!());
  }, [tablePage, tableFilters, forceFetchData]);

  const getFullTableRequest = (): BaseFullTableRequest => {
    return {
      search: tablePage.q,
      ...tableFilters,
    };
  };
  const addExtraFieldRequest = (): ExtraPaginatedRequestFields => {
    return {
      id: id,
    };
  };

  const searchValueChangeHandler = (q: string) => {
    setTablePage({ ...tablePage, q });
  };

  const pageChanged = (limit: number, offset: number) => {
    setTablePage({ ...tablePage, limit, offset });
  };

  return (
    <>
      {(search || (massiveActions != null && massiveActions.length > 0)) && (
        <Form>
          <Form.Group className="mb-3">
            <div className="sst-horizontal-table">
              {search != null ? <Form.Control placeholder=" Search" onChange={(e) => searchValueChangeHandler(e.target.value)} /> : null}
              {massiveActions != null && massiveActions.length > 0 ? (
                <Dropdown drop="down-centered">
                  <Dropdown.Toggle variant="outline-primary" className="btn-icon" id="dropdown-tr-filter">
                    <i className={`bi bi-three-dots-vertical pointer`}></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {massiveActions.map((ma) => (
                      <Dropdown.Item key={ma.label} onClick={() => ma.fnc(getFullTableRequest())}>
                        {ma.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              ) : null}
            </div>
          </Form.Group>
        </Form>
      )}
      <Table<T>
        columns={columns.map((c) => ({
          ...c,
          onFilterChange: (values: string[]) => {
            if (c.id != null) {
              setTableFilters({ ...tableFilters, [c.id]: values.length > 0 ? values : undefined });
            } else {
              console.log(`Missed filter id for column ${c.title}`);
            }
          },
        }))}
        rows={tableRows}
        rowClickHandler={rowClickHandler}
        key={key}
        onPageChanged={pageChanged}
        totalCount={tableTotalRows}
      ></Table>
    </>
  );
}

export default ServerSideTable;
