import api from "api";
import { LocationTimeslotType } from "models/timeslots";
import { useEffect, useMemo, useState } from "react";
import { Accordion, Dropdown, Spinner } from "react-bootstrap";
import { groupBy } from "utilities/functionUtils";

type Props = {
  locationId: string
}
export default ({ locationId }: Props) => {
  const [loading,setLoading] = useState<boolean>(true);

  const [data,setData] = useState<LocationTimeslotType[]>([]);
  useEffect(() => {
    refreshData();
  }, [locationId]);

  const refreshData = () => {
    setLoading(true)
    return api.locations.getLocationTimeslots(locationId).then((res) => {
      setData(res);
    }).finally(() => setLoading(false))
  }

  const days = [1,2,3,4,5,6,0];
  const daysLabel = {
    0: 'Domenica',
    1: 'Lunedì',
    2: 'Martedì',
    3: 'Mercoledì',
    4: 'Giovedì',
    5: 'Venerdì',
    6: 'Sabato',
  };

  const daysArray = useMemo(() => {
    const array = {
      0: [],
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
    };
    data.forEach((elem) => {
      (array as any)[elem.dayOfWeek].push(elem);
    })

    days.forEach((d) => {
      ((array as any)[d] as LocationTimeslotType[]).sort((a, b) => a.fromHour - b.fromHour );
    })
    return array;
  }, [data]);

  const onUpdateTimeSlotClick = (elem: LocationTimeslotType, val: number) => {

    const oldStatusVal = getTimeslotStatusNumber(elem);
    if(oldStatusVal == val) {
      // MEMO: il valore è già aggiornato.
      return;
    }

    let prom = null;
    if(val == 0) {
      // TODO: Eliminare record
      prom = api.locations.removeLocationTimeslots(locationId, elem.id);
    } else if (val === 1 || val === 2 || val === 3) {
      let newDiscountPercVal = 0;
      switch(val) {
        case 2: newDiscountPercVal = 5;  break;
        case 3: newDiscountPercVal = 10; break;
      }
      if(elem.locationTimeslotId != null) {
        // TODO: Aggiornare record
        prom = api.locations.updateLocationTimeslots(locationId, elem.id, newDiscountPercVal);
      } else {
        // TODO: Inserire record
        prom = api.locations.addLocationTimeslots(locationId, elem.id, newDiscountPercVal);
      }
    }

    if(prom != null) {
      setLoading(true);
      prom.then(res => {
        // MEMO: non bellissimo ricaricare tutto, ma per ora va bene.
        return refreshData();
      }).finally(() => setLoading(false))
    }
  }

  const getTimeslotStatusNumber = (elem: LocationTimeslotType) => {
    if(!elem.enabled) {
      return 0;
    }
    if(elem.discountPerc == null || elem.discountPerc === 0) {
      return 1
    } else if(elem.discountPerc === 5) {
      return 2
    }else if(elem.discountPerc === 10) {
      return 3
    } else {
      return -1;
    }
  }

  const timeslotStatusLabel = {
    '-1': '#NON DEFINITO',
    '0': 'Disabilitato',
    '1': 'Abilitato senza sconto',
    '2': 'Abilitato: -5%',
    '3': 'Abilitato: -10%',
  };

  if(loading) {
    return <Spinner />
  }

  return <Accordion defaultActiveKey="0">


{days.map(d =>
    <Accordion.Item key={d+ ''} eventKey={d + ''}>
      <Accordion.Header>{(daysLabel as any)[d]}</Accordion.Header>
      <Accordion.Body>
        <div className="d-flex " style={{gap: 2}}>
      {(daysArray as any)[d].map((elem: LocationTimeslotType) =>
      <div key={`d-${elem.dayOfWeek}-${elem.fromHour}-${elem.toHour}`}>DA {elem.fromHour} A {elem.toHour}:
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            {(timeslotStatusLabel as any)[getTimeslotStatusNumber(elem)]}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => onUpdateTimeSlotClick(elem, 0)}>{timeslotStatusLabel[0]}</Dropdown.Item>
            <Dropdown.Item onClick={() => onUpdateTimeSlotClick(elem, 1)}>{timeslotStatusLabel[1]}</Dropdown.Item>
            <Dropdown.Item onClick={() => onUpdateTimeSlotClick(elem, 2)}>{timeslotStatusLabel[2]}</Dropdown.Item>
            <Dropdown.Item onClick={() => onUpdateTimeSlotClick(elem, 3)}>{timeslotStatusLabel[3]}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      )}</div>
      </Accordion.Body>
    </Accordion.Item>
    )}
    </Accordion>;
};
