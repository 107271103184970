import React, { ChangeEvent, useState } from 'react';
import { Button } from 'react-bootstrap';

type PropsType = {
  onConfirm: (files: File[]) => void;
};

function UploadButton({ onConfirm }: PropsType) {
  const [file, setFile] = useState<File>();

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleUploadClick = () => {
    if (!file) {
      return;
    }

    onConfirm([file]);
  };

  return (
    <div className="m-3">
      <input type="file" onChange={handleFileChange} />

      <div>{file && `${file.name} - ${file.type}`}</div>
      <Button className="btn-outline-primary" onClick={handleUploadClick}>
        Confirm
      </Button>
    </div>
  );
}
export default UploadButton;
