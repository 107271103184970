import api from 'api';
import PageContainer from 'components/common/PageContainer';
import ServerSideTable from 'components/table/ServerSideTable';
import TableButton from 'components/table/TableButton';
import { ItemCmp, CmpItemDetail } from 'models/Items';
import { TableColumnType, TableMassiveActionsType } from 'models/table';
import { useState } from 'react';
import { Card, Container, Dropdown } from 'react-bootstrap';
import { BaseFullTableRequest, ListElement } from 'models/common';
import { getListElementArray } from 'utilities/listUtils';
import { useNavigate, useParams } from 'react-router-dom';
import urlUtils from 'utilities/urlUtils';
import ConfirmModal from 'components/common/ConfirmModal';

export default () => {
  const { id } = useParams();
  const [forceFetchData, setForceFetchData] = useState<boolean>(false);
  const [itemDeleteRequest, setitemDeleteRequest] = useState<string | undefined>();
  const onDeleteRequest = (id: string) => {
    setitemDeleteRequest(id);
  };

  const navigate = useNavigate();

  const onDeleteItemConfirm = (id: string | null | undefined) => {
    console.log('entro nel delete');

    if (id != null) {
      api.items.removeItemDetail(id).then(() => {
        setForceFetchData(true);
        setitemDeleteRequest(undefined);
      });
    }
  };

  const onItemDeleteCancel = () => {
    setitemDeleteRequest(undefined);
  };

  const tableColumns: TableColumnType<CmpItemDetail>[] = [
    {
      title: 'Nome',
      render: (row) => row.name,
    },
    {
      title: 'Info',
      render: (row) => row.info,
    },
    {
      title: 'Is Multiple',
      render: (row) => (row.isMultiple ? 'Si' : 'No'),
    },
    {
      title: 'Is VAR',
      render: (row) => (row.isVAR ? 'Si' : 'No'),
    },
    {
      title: 'Image',
      render: (row) => row.img,
    },
    {
      title: '',
      render: (row) => (
        <>
          <TableButton tooltipText="Modifica" iconClass="bi bi-pencil" onClick={() => navigate(urlUtils.items.editDetails(row.id))} />
          <TableButton tooltipText="Elimina" iconClass="bi bi-trash" onClick={() => onDeleteRequest(row.id)} />
        </>
      ),
    },
  ];

  return (
    <PageContainer>
      <Card>
        <Card.Body>
          <ServerSideTable
            search={true}
            columns={tableColumns}
            fetchApi={api.items.getItemDetails}
            forceFetchData={forceFetchData}
            onForceFetchDataCompleted={() => setForceFetchData(false)}
          />
        </Card.Body>
      </Card>
      <ConfirmModal
        show={itemDeleteRequest != null}
        title="Elimina item detail"
        content="Sicuro di voler eliminare il singolo dettaglio ?"
        onOk={() => onDeleteItemConfirm(itemDeleteRequest)}
        onCancel={onItemDeleteCancel}
      />
    </PageContainer>
  );
};
