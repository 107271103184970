function internalGetDateString(d: Date) {
  if (d == null) {
    return null;
  }
  return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
}

function internalGetTimeString(d: Date) {
  if (d == null) {
    return null;
  }
  let str = `${d.getHours()}:`;
  if (d.getMinutes() < 10) {
    str += '0';
  }
  str += d.getMinutes();
  return str;
}

export function getDateString(dt: string | undefined | null) {
  if (dt == null) {
    return null;
  }
  const d = new Date(dt);
  return internalGetDateString(d);
}

export function getDateTimeString(dt: string | undefined | null) {
  if (dt == null) {
    return null;
  }
  const d = new Date(dt);
  return `${internalGetDateString(d)} ${internalGetTimeString(d)}`;
}

export function getTimeString(dt: string | undefined | null) {
  if (dt == null) {
    return null;
  }
  const d = new Date(dt);
  return internalGetTimeString(d);
}

export function getValueLabel(value: any | undefined, labelObj: any) {
  if (value == null) {
    return '-';
  }
  const found = labelObj[value];
  if (found != null) {
    return found;
  }
  return value;
}

export function getCurrencyString(value: number | string | undefined) {
  if (value == null) {
    return '-';
  }
  return `${(+value).toFixed(2)}€`;
}

export function convertToCurrencyOrPerc(val: number, isAbsolute: boolean) {
  return isAbsolute ? getCurrencyString(val) : `${val}%`;
}

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    // Read file content on file loaded event
    reader.onload = function (event) {
      resolve(event?.target?.result as string);
    };

    // Convert data to base64
    reader.readAsDataURL(file);
  });
};

export function groupBy(arr: any[], property: string) {
  return arr.reduce(function (memo, x) {
      if (!memo[x[property]]) { memo[x[property]] = []; }
      memo[x[property]].push(x);
      return memo;
  }, {});
};