import client from 'api/client';
import useAuthStore from 'store/authStore';

export function login(username: string, password: string): Promise<any> {
  return client
    .post(`auth/sign-in`, { email: username, password })
    .then((result: any) => {
      useAuthStore.getState().setUser(result.user, result.token);
    })
    .catch((err: any) => {
      // Something is Wrong
      console.error('LOGIN ERROR', err);
    });
}

export function logout(): Promise<any> {
  return new Promise((resolve, reject) => {
    resolve(null);
  });
}
