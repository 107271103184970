import React, { ReactNode, useContext } from 'react';
// import AuthContext, { removeJwtToken } from 'context/authContext';
import { useNavigate } from 'react-router-dom';
import urlUtils from 'utilities/urlUtils';
// import api from 'api';
import { Container, Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';
import useAppStore from 'store/appStore';
import useAuthStore from 'store/authStore';
import api from 'api';

export default () => {
  const navigate = useNavigate();
  const { isLogged, getFullName, clearUser } = useAuthStore();

  const onLogoutButtonClick = () => {
    api.auth.logout().then(() => {
      clearUser();
      navigate(urlUtils.login(), { replace: true });
    });
  };

  return (
    <Navbar>
      <Container fluid>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            {isLogged() ? (
              <NavDropdown align="end" title={getFullName()} id="collasible-nav-dropdown">
                <NavDropdown.ItemText>
                  <Button variant="danger" onClick={() => onLogoutButtonClick()} style={{ width: '100%' }}>
                    Logout
                  </Button>
                </NavDropdown.ItemText>
              </NavDropdown>
            ) : null}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
