import { UserProfile } from 'models/auth';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

type StoreType = {
  token: string | undefined;
  user: UserProfile | undefined;
  getFullName: () => string;
  isLogged: () => boolean;
  setUser: (usr: UserProfile, tkn: string) => void;
  clearUser: () => void;
};

const useAuthStore = create(
  persist<StoreType>(
    (set, get) => ({
      token: undefined,
      user: undefined,
      getFullName: () => (get().isLogged() ? [get().user?.name, get().user?.surname].join(' ') : ''),
      isLogged: () => get().token != null,
      setUser: (usr, tkn) => {
        set((state) => ({ ...state, token: tkn, user: usr }));
      },
      clearUser: () => set((state) => ({ ...state, token: undefined, user: undefined })),
    }),
    {
      name: 'auth', // unique name
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    },
  ),
);

export default useAuthStore;
