import { ListElement } from 'models/common';

export const getListElementArray = (valuesEnum: any, labelValues: any): ListElement[] => {
  return Object.keys(valuesEnum).map(
    (k) =>
      ({
        label: labelValues[valuesEnum[k]],
        value: valuesEnum[k],
      } as ListElement),
  );
};
