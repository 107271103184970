import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

type StoreType = {
  isMenuCollapsed: boolean;
  setMenuStatus: (collapsed: boolean) => void;
};

const useAppStore = create(
  persist<StoreType>(
    (set, get) => ({
      isMenuCollapsed: false,
      setMenuStatus: (collapsed) => set((state) => ({ ...state, isMenuCollapsed: collapsed })),
    }),
    {
      name: 'app', // unique name
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    },
  ),
);

export default useAppStore;
