import client, { downloadFile } from 'api/client';
import { BaseFullTableRequest, BasePaginatedData } from 'models/common';
import { LocationBodyVM, LocationPaginatedRequest, LocationType } from 'models/locations';
import { LocationTimeslotType } from 'models/timeslots';

export function get(pq: LocationPaginatedRequest): Promise<BasePaginatedData<LocationType>> {
  return client
    .post(`locations/get`, pq)
    .then((result: any) => {
      return result;
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}

export function getDetail(id: string): Promise<LocationType> {
  return client
    .get(`locations/${id}`)
    .then((result: any) => {
      return result;
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}

export function add(pq: LocationBodyVM): Promise<LocationType> {
  return client
    .post(`locations`, pq)
    .then((result: any) => {
      return result;
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}

export function edit(id: string, pq: LocationBodyVM): Promise<LocationType> {
  return client
    .put(`locations/${id}`, pq)
    .then((result: any) => {
      return result;
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}

export function remove(id: string): Promise<void> {
  return client
    .delete(`locations/${id}`)
    .then(() => {
      console.log('location rimossa');
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}

export function getLocationTimeslots(id: string): Promise<LocationTimeslotType[]> {
  return client
    .get(`locations/${id}/timeslots`)
    .then((result: any) => {
      return result;
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}

export function addLocationTimeslots(locationId: string, timeslotId: string, val: number): Promise<void> {
  return client
    .post(`locations/${locationId}/timeslots/${timeslotId}`, {
      discountPerc: val
    })
    .then((result: any) => {
      return result;
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}

export function updateLocationTimeslots(locationId: string, timeslotId: string, val: number): Promise<void> {
  return client
    .put(`locations/${locationId}/timeslots/${timeslotId}`, {
      discountPerc: val
    })
    .then((result: any) => {
      return result;
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}
export function removeLocationTimeslots(locationId: string, timeslotId: string): Promise<void> {
  return client
    .delete(`locations/${locationId}/timeslots/${timeslotId}`)
    .then((result: any) => {
      return result;
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}