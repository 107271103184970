import api from 'api';
import ConfirmModal from 'components/common/ConfirmModal';
import PageContainer from 'components/common/PageContainer';
import SelectableDropdown from 'components/common/SelectableDropdown';
import ServerSideTable from 'components/table/ServerSideTable';
import TableButton from 'components/table/TableButton';
import { ORDER_STATUS, ORDER_STATUS_LABEL } from 'constants/orders';
import { BaseFullTableRequest, ListElement } from 'models/common';
import { LocationType } from 'models/locations';
import { OrderCmp } from 'models/orders';
import { TableColumnType, TableMassiveActionsType } from 'models/table';
import { useState } from 'react';
import { Button, Card, Container, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getValueLabel } from 'utilities/functionUtils';
import { getListElementArray } from 'utilities/listUtils';
import urlUtils from 'utilities/urlUtils';
import { FAKE_NEW_ID } from './LocationDetailPage';

export default () => {
  const [forceFetchData, setForceFetchData] = useState<boolean>(false);

  const navigate = useNavigate();

  const onDeleteLocation = (id: string | null | undefined) => {
    if (id != null) {
      api.locations.remove(id).then(() => {
        setForceFetchData(true);
      });
    }
  };

  const tableColumns: TableColumnType<LocationType>[] = [
    {
      title: 'Città',
      render: (row) => row.city,
    },
    {
      title: 'CAP',
      render: (row) => row.postalCode,
    },
    {
      title: 'Provincia',
      render: (row) => row.province,
    },
    {
      title: 'UID',
      render: (row) => row.key,
    },
    {
      title: '',
      render: (row) => (
        <>
          <TableButton tooltipText="Modifica" iconClass="bi bi-pencil" onClick={() => navigate(urlUtils.locations.detail(row.id))} />
          {/* MEMO: Per ora non posso modificare la località. <TableButton tooltipText="Elimina" iconClass="bi bi-trash" onClick={() => onDeleteLocation(row.id)} /> */}
        </>
      ),
    },
  ];

  const massiveActions: TableMassiveActionsType[] = [
    /* {
      label: 'Download ordini',
      fnc: (req: BaseFullTableRequest) => api.orders.exportOrdersDetails(req),
    }, */
  ];

  return (
    <PageContainer>
      <Card>
        <Card.Body>
          <ServerSideTable
            search={true}
            columns={tableColumns}
            fetchApi={api.locations.get}
            forceFetchData={forceFetchData}
            onForceFetchDataCompleted={() => setForceFetchData(false)}
            massiveActions={massiveActions}
          />
          <Button onClick={() => navigate(urlUtils.locations.detail(FAKE_NEW_ID))}>Aggiungi località</Button>
        </Card.Body>
      </Card>
      {/* <ConfirmModal
        show={statusChangeOrder != null && statusChangeValue != null}
        title="Modifica stato ordine"
        content={`Sei sicuro di voler modificare lo stato dell'ordine "${statusChangeOrder?.number ?? ''}" con lo stato "${getValueLabel(
          statusChangeValue,
          ORDER_STATUS_LABEL,
        )}"?`}
        onOk={() => onStatusChangedConfirm(statusChangeOrder, statusChangeValue)}
        onCancel={onStatusChangedCancel}
      /> */}
    </PageContainer>
  );
};
