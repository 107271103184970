import api from 'api';
import PageContainer from 'components/common/PageContainer';
import ServerSideTable from 'components/table/ServerSideTable';
import TableButton from 'components/table/TableButton';
import { ItemCmp } from 'models/Items';
import { TableColumnType, TableMassiveActionsType } from 'models/table';
import { useState } from 'react';
import { Card, Container, Dropdown } from 'react-bootstrap';
import { BaseFullTableRequest, ListElement } from 'models/common';
import { getListElementArray } from 'utilities/listUtils';
import { useNavigate } from 'react-router-dom';
import urlUtils from 'utilities/urlUtils';
import ConfirmModal from 'components/common/ConfirmModal';


export default () => {
  const [forceFetchData, setForceFetchData] = useState<boolean>(false);
  const [itemDeleteRequest, setitemDeleteRequest] = useState<string | undefined>();
  const onDeleteRequest = (id: string) => {
    setitemDeleteRequest(id);
  };

  const navigate = useNavigate();

  const onDeleteItemConfirm = (id: string | null | undefined) => {
    console.log('entro nel delete');

    if (id != null) {
      api.items.remove(id).then(() => {
        setForceFetchData(true);
        setitemDeleteRequest(undefined);
      });
    }
  };

  const onItemDeleteCancel = () => {
    setitemDeleteRequest(undefined);
  };

  const tableColumns: TableColumnType<ItemCmp>[] = [
    {
      id: 'brandId',
      title: 'Brand',
      render: (row) => row.brand,
    },
    {
      title: 'Nome',
      render: (row) => row.name,
    },
    {
      title: 'Info',
      render: (row) => row.info,
    },
    {
      title: 'Description',
      render: (row) => row.description,
    },
    {
      title: 'Image',
      render: (row) => row.image,
    },
    {
      title: 'Category',
      render: (row) => row.category,
    },
    {
      title: '',
      render: (row) => (
        <>
          <TableButton tooltipText="Modifica" iconClass="bi bi-pencil" onClick={() => navigate(urlUtils.items.edit(row.id))} />
          <TableButton tooltipText="Elimina" iconClass="bi bi-trash" onClick={() => onDeleteRequest(row.id)} />
          <TableButton tooltipText="Dettagli" iconClass="bi bi-card-list" onClick={() => navigate(urlUtils.items.details(row.id))} />
        </>
      ),
    },
  ];

  return (
    <PageContainer>
      <Card>
        <Card.Body>
          <ServerSideTable
            search={true}
            columns={tableColumns}
            fetchApi={api.items.getItems}
            forceFetchData={forceFetchData}
            onForceFetchDataCompleted={() => setForceFetchData(false)}
          />
        </Card.Body>
      </Card>
      <ConfirmModal
        show={itemDeleteRequest != null}
        title="Elimina Item"
        content="Sicuro di voler eliminare l'item e tutti i suoi details ?"
        onOk={() => onDeleteItemConfirm(itemDeleteRequest)}
        onCancel={onItemDeleteCancel}
      />
    </PageContainer>
  );
};
