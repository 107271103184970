export enum ORDER_STATUS {
  Unknown = 'unknown',
  DeletedBySubscription = 'deleted-by-subscription',
  Draft = 'draft',
  Pending = 'pending',
  Paid = 'paid',
  NotPaid = 'not-paid',
  Delivering = 'delivering',
  Delivered = 'delivered',
}
export const ORDER_STATUS_LABEL = {
  [ORDER_STATUS.Unknown]: '-',
  [ORDER_STATUS.DeletedBySubscription]: 'Eliminato',
  [ORDER_STATUS.Draft]: 'In scrittura',
  [ORDER_STATUS.Pending]: 'Confermato',
  [ORDER_STATUS.Paid]: 'Pagato',
  [ORDER_STATUS.NotPaid]: 'Non pagato',
  [ORDER_STATUS.Delivering]: 'In elaborazione',
  [ORDER_STATUS.Delivered]: 'Spedito',
};

export enum ORDER_WAREHOUSE_STATUS {
  Unknown = 'unknown',
  Deleted = 'deleted',
  Paused = 'paused',
  Waiting = 'waiting',
  ToDo = 'to-do',
  InProcessing = 'in-processing',
  Processed = 'processed',
  DeliveredToTheCourier = 'delivered-to-the-courier',
}

export const ORDER_WAREHOUSE_STATUS_LABEL = {
  [ORDER_WAREHOUSE_STATUS.Unknown]: '-',
  [ORDER_WAREHOUSE_STATUS.Deleted]: 'Eliminato',
  [ORDER_WAREHOUSE_STATUS.Paused]: 'In pausa',
  [ORDER_WAREHOUSE_STATUS.Waiting]: 'In attesa',
  [ORDER_WAREHOUSE_STATUS.ToDo]: 'Da elaborare',
  [ORDER_WAREHOUSE_STATUS.InProcessing]: 'In elaborazione',
  [ORDER_WAREHOUSE_STATUS.Processed]: 'Elaborato',
  [ORDER_WAREHOUSE_STATUS.DeliveredToTheCourier]: 'Consegnato al corriere',
};
