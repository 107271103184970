import { useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

type PropsType = {
  icon?: string;
  label: string;
  to: string;
};

export default ({ icon, label, to }: PropsType) => {
  const activeClassName = '';
  const location = useLocation();

  return (
    <li className={`menu-elem ${location.pathname.startsWith(to) || (to.startsWith('/home') && location.pathname == '/') ? 'selected' : ''}`}>
      <NavLink to={to} className={({ isActive }) => (isActive ? activeClassName : undefined)}>
        {/* <div className="menu-elem-icon">{icon != null ? <Icon type="message" style={{ fontSize: '16px' }} /> : null}</div> */}
        <div className="menu-elem-icon">{icon != null ? <i className={icon} /> : null}</div>
        <div className="menu-elem-label">{label}</div>
      </NavLink>
    </li>
  );
};
