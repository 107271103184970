import { Button, Modal } from 'react-bootstrap';

type PropsType = {
  show: boolean;
  title: string;
  content: string;
  onOk: () => void;
  onCancel: () => void;
};

export default ({ show, title, content, onOk, onCancel }: PropsType) => {
  return (
    <Modal show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Annulla
        </Button>
        <Button variant="primary" onClick={onOk}>
          Conferma
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
