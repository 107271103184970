import client, { downloadFile } from 'api/client';
import { BaseFullTableRequest, BasePaginatedData } from 'models/common';
import { OrderCmp, OrderPaginatedRequest } from 'models/orders';

export function get(pq: OrderPaginatedRequest): Promise<BasePaginatedData<OrderCmp>> {
  return client
    .post(`orders/get`, pq)
    .then((result: any) => {
      return result;
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}

export function updateStatus(id: string, status: string): Promise<OrderCmp> {
  return client
    .post(`orders/${id}/status`, { status })
    .then((result: any) => {
      return result;
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}


export function updateWarehouseStatus(id: string, status: string): Promise<OrderCmp> {
  return client
    .post(`orders/${id}/warehouse-status`, { status })
    .then((result: any) => {
      return result;
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}

export function downloadOrderDetails(id: string): Promise<void> {
  return client
    .post(`orders/${id}/details/export`)
    .then((result: any) => {
      downloadFile(result.fileName, result.fileContent);
      return;
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}

export function downloadOrders(id: string): Promise<void> {
  return client
    .post(`orders/${id}/details/export`)
    .then((result: any) => {
      downloadFile(result.fileName, result.fileContent);
      return;
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}

export function exportOrdersDetails(pq: BaseFullTableRequest): Promise<void> {
  return client
    .post(`orders/details/export`, pq)
    .then((result: any) => {
      downloadFile(result.fileName, result.fileContent);
    })
    .catch((err: any) => {
      // Something is Wrong
    });
}
