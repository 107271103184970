import LoginCard from 'components/login/LoginCard';
import { Container } from 'react-bootstrap';

export default () => {
  return (
    <Container className="login-container">
      <LoginCard />
    </Container>
  );
};
