import CheckboxDropdownItems, { CheckboxDropdownItemType } from 'components/common/CheckboxDropdownItems';
import { ListElement } from 'models/common';
import { useState } from 'react';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';

type ColumnFilterProps = {
  defaultValues?: string[];
  items: ListElement[];
  onChange: (values: string[]) => void;
};

export default ({ defaultValues, items, onChange }: ColumnFilterProps) => {
  const [filtered, setFiltered] = useState(false);

  const onChangeWrapper = (values: string[]) => {
    const filteredNewValue = values != null && values.length > 0;
    if (filtered != filteredNewValue) {
      setFiltered(filteredNewValue);
    }
    onChange(values);
  };
  return (
    <Dropdown drop="down-centered">
      <Dropdown.Toggle variant="outline-primary" className="btn-icon" id="dropdown-tr-filter">
        <i className={`bi ${filtered ? 'bi bi-funnel-fill' : 'bi-funnel'} pointer`}></i>
      </Dropdown.Toggle>
      <CheckboxDropdownItems defaultValues={defaultValues} items={items} onChange={onChangeWrapper} />
    </Dropdown>
  );
};
