import { ListElement } from 'models/common';
import React, { useState } from 'react';

import { Button, ButtonGroup, Dropdown, Form } from 'react-bootstrap';

type CheckboxMenuProps = {
  children: any;
  style: React.CSSProperties;
  className: string;
  labeledBy?: any;
  onSelectAll: () => void;
  onSelectNone: () => void;
};

const CheckboxMenu = ({ children, style, className, labeledBy, onSelectAll, onSelectNone }: CheckboxMenuProps) => {
  return (
    <div style={style} className={`${className} CheckboxMenu`} aria-labelledby={labeledBy}>
      <div className="d-flex flex-column" style={{ maxHeight: 'calc(100vh)', overflow: 'none' }}>
        <ul className="list-unstyled flex-shrink mb-0" style={{ overflow: 'auto' }}>
          {children}
        </ul>
        <div className="dropdown-item border-top pt-2 pb-0">
          <ButtonGroup size="sm">
            <Button variant="link" onClick={onSelectAll}>
              Select All
            </Button>
            <Button variant="link" onClick={onSelectNone}>
              Select None
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
};

type CheckDropdownItemProps = {
  children: React.ReactNode;
  id?: string;
  checked: any;
  onChange: (id: string | undefined, event: any) => void;
};

const CheckDropdownItem = ({ children, id, checked, onChange }: CheckDropdownItemProps) => {
  return (
    <Form.Group className="dropdown-item mb-0" controlId={id}>
      {/* <Form.Check type="checkbox" label={children} checked={checked} onChange={onChange && onChange.bind(onChange, id)} /> */}
      <Form.Check type="checkbox" label={children} checked={checked} onChange={(e) => onChange(id, e)} />
    </Form.Group>
  );
};

export type CheckboxDropdownItemType = {
  id: string;
  checked: boolean;
  label: string | undefined;
};

type CheckboxDropdownProps = {
  defaultValues?: string[];
  items: ListElement[];
  onChange: (values: string[]) => void;
};

export default ({ items, defaultValues, onChange }: CheckboxDropdownProps) => {
  const [checkedValues, setCheckedValues] = useState<string[]>(defaultValues ?? []);

  const onCheckedChanged = (value: string) => {
    const idx = checkedValues.indexOf(value);
    if (idx === -1) {
      const newVal = [...checkedValues, value];
      setCheckedValues(newVal);
      onChange(newVal);
    } else {
      checkedValues.splice(idx, 1);
      const newVal = [...checkedValues];
      setCheckedValues(newVal);
      onChange(newVal);
    }
  };

  const handleSelectAll = () => {
    setCheckedValues(items.map((it) => it.value));
  };

  const handleSelectNone = () => {
    setCheckedValues([]);
  };

  return (
    <Dropdown.Menu as={CheckboxMenu} onSelectAll={handleSelectAll} onSelectNone={handleSelectNone}>
      {items.map((i) => (
        <Dropdown.Item key={i.value} as={CheckDropdownItem} id={i.value} checked={checkedValues.includes(i.value)} onChange={() => onCheckedChanged(i.value)}>
          {i.label}
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  );
};
