import api from 'api';
import Button from 'components/common/Button';
import FormField from 'components/form/FormField';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import settings from 'settings';
import urlUtils from 'utilities/urlUtils';
import * as Yup from 'yup';

export default () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getInitFormData = () => {
    return {
      email: settings?.loginEmail ?? '',
      password: settings?.loginPassword ?? '',
    };
  };

  const signInSchema = Yup.object().shape({
    email: Yup.string().required('Email richiesta'),
    password: Yup.string().required('Nome richiesto'),
  });

  return (
    <Card style={{ width: '18rem' }} className="text-center">
      <Formik
        initialValues={getInitFormData()}
        enableReinitialize
        validationSchema={signInSchema}
        onSubmit={(values: any) => {
          setLoading(true);
          console.log('Login onSubmit');
          api.auth
            .login(values.email, values.password)
            .then(() => navigate(urlUtils.main()))
            .catch(() => setLoading(false));
        }}
      >
        {(formik) => {
          const { errors, touched, isValid, dirty, handleChange, handleBlur, values } = formik;
          return (
            <Form>
              <Card.Body>
                <Card.Title>LOGIN</Card.Title>
                <Card.Text>
                  <FormField name="email" label="Email" errors={errors} touched={touched} onChange={handleChange} onBlur={handleBlur} />
                  <FormField name="password" label="Password" type="password" errors={errors} touched={touched} onChange={handleChange} onBlur={handleBlur} />
                </Card.Text>
                <Button variant="primary" type="submit" loading={loading}>
                  Login
                </Button>
              </Card.Body>
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
};
