import React, { ReactNode } from 'react';
import { Pagination as BootstrapPagination } from 'react-bootstrap';

interface PaginationProps {
  limit: number;
  offset: number;
  totalCount: number;
  onPageChanged: (limit: number, offset: number) => any;
}

interface ButtonProps {
  page: number;
  currentPage: number;
  onClick: () => any;
}

const PaginationButton = ({ page, currentPage, onClick }: ButtonProps) => (
  <BootstrapPagination.Item className={`paginating-controls-select-button ${page == currentPage ? 'active' : ``}`} onClick={() => onClick()}>
    {page}
  </BootstrapPagination.Item>
);

const Ellipsis = () => <BootstrapPagination.Ellipsis className="paginating-controls-ellipsis">. . .</BootstrapPagination.Ellipsis>;

function Pagination({ limit, offset, totalCount, onPageChanged }: PaginationProps) {
  const prevString = '<';
  const nextString = '>';
  const currentPage = Math.floor(offset / limit) + 1;
  const buttonsCount = 3;

  const pageCount = () => Math.ceil(totalCount / limit);

  const onPreviousButtonClicked = () => {
    if (offset - limit >= 0) {
      onPageChanged(limit, offset - limit);
    }
  };
  const onNextButtonClicked = () => {
    if (currentPage < pageCount()) {
      onPageChanged(limit, offset + limit);
    }
  };
  const onPageButtonClicked = (page: number) => {
    if (page > currentPage) {
      onPageChanged(limit, offset + (page - currentPage) * limit);
    } else {
      onPageChanged(limit, offset - (currentPage - page) * limit);
    }
  };

  const prevEllipsis = () => {
    if (currentPage - Math.ceil(buttonsCount / 2) > 1 && pageCount() > buttonsCount + 2) {
      if (currentPage - Math.ceil(buttonsCount / 2) == 2) return <PaginationButton currentPage={currentPage} page={2} onClick={() => onPageButtonClicked(2)} />;
      else return <Ellipsis />;
    }
  };
  const nextEllipsis = () => {
    if (pageCount() - currentPage > Math.ceil(buttonsCount / 2) && pageCount() > buttonsCount + 2) {
      if (pageCount() - (currentPage + Math.ceil(buttonsCount / 2)) == 1)
        return <PaginationButton currentPage={currentPage} page={pageCount() - 1} onClick={() => onPageButtonClicked(pageCount() - 1)} />;
      else return <Ellipsis />;
    }
  };
  const paginationButtons = () => {
    if (pageCount() >= 2) {
      const buttons = [];
      if (pageCount() > buttonsCount + 2) {
        if (currentPage <= Math.ceil(buttonsCount / 2)) {
          for (let i = 2; i <= buttonsCount; i++) {
            buttons.push(<PaginationButton currentPage={currentPage} page={i} onClick={() => onPageButtonClicked(i)} />);
          }
        } else if (currentPage >= pageCount() - Math.floor(buttonsCount / 2)) {
          for (let i = pageCount() - Math.ceil(buttonsCount / 2); i < pageCount(); i++) {
            buttons.push(<PaginationButton currentPage={currentPage} page={i} onClick={() => onPageButtonClicked(i)} />);
          }
        } else {
          for (let i = currentPage - Math.floor(buttonsCount / 2); i <= currentPage + Math.floor(buttonsCount / 2); i++) {
            buttons.push(<PaginationButton currentPage={currentPage} page={i} onClick={() => onPageButtonClicked(i)} />);
          }
        }
      } else {
        for (let i = 0; i < pageCount() - 2; i++) {
          buttons.push(<PaginationButton currentPage={currentPage} page={i + 2} onClick={() => onPageButtonClicked(i + 2)} />);
        }
      }
      return buttons;
    }
  };

  return (
    <BootstrapPagination className="paginating-controls-main-container">
      <BootstrapPagination.Prev className="paginating-controls-prev-next-button" onClick={onPreviousButtonClicked}>
        {prevString}
      </BootstrapPagination.Prev>
      <PaginationButton currentPage={currentPage} page={1} onClick={() => onPageButtonClicked(1)} />
      {prevEllipsis()}
      {paginationButtons()}
      {nextEllipsis()}
      {pageCount() > 1 && <PaginationButton currentPage={currentPage} page={pageCount()} onClick={() => onPageButtonClicked(pageCount())} />}
      <BootstrapPagination.Next className="paginating-controls-prev-next-button" onClick={onNextButtonClicked}>
        {nextString}
      </BootstrapPagination.Next>
    </BootstrapPagination>
  );
}

export default Pagination;
