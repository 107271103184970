import HomePage from 'pages/HomePage';
import LoginPage from 'pages/auth/LoginPage';
import CouponDetailPage from 'pages/coupons/CouponDetailPage';
import CouponsPage from 'pages/coupons/CouponsPage';
import DevToolsItemsPage from 'pages/devTools/DevToolsItemsPage';
import DevToolsOrdersPage from 'pages/devTools/DevToolsOrdersPage';
import DevToolsPage from 'pages/devTools/DevToolsPage';
import OrdersPage from 'pages/orders/OrdersPage';
import ItemsPage from 'pages/Items/ItemsPage';
import ItemEditPage from 'pages/Items/ItemEditPage';
import ItemDetailsPage from 'pages/Items/ItemDetailsPage';
import ItemDetailEditPage from 'pages/Items/ItemDetailEditPage';
import SettingsPage from 'pages/settings/SettingsPage';
import urlUtils from 'utilities/urlUtils';
import LocationsPage from 'pages/locations/LocationsPage';
import LocationDetailPage from 'pages/locations/LocationDetailPage';

export const unauth: any[] = [
  {
    name: 'login',
    title: 'Login',
    path: urlUtils.login(),
    element: <LoginPage />,
  },
];

export const auth: any[] = [
  {
    name: 'page-1',
    title: 'Page 1',
    path: urlUtils.page1.main(),
    element: <HomePage />,
  },
  {
    name: 'devtools',
    title: 'DevTools',
    path: urlUtils.devtools.main(),
    element: <DevToolsPage />,
  },
  {
    name: 'devtools-items',
    title: 'DevTools - Items',
    path: urlUtils.devtools.items(),
    element: <DevToolsItemsPage />,
  },
  {
    name: 'devtools-orders',
    title: 'DevTools - Orders',
    path: urlUtils.devtools.orders(),
    element: <DevToolsOrdersPage />,
  },
  {
    name: 'settings',
    title: 'Settings',
    path: urlUtils.settings.main(),
    element: <SettingsPage />,
  },
  {
    name: 'orders',
    title: 'Ordini',
    path: urlUtils.orders.main(),
    element: <OrdersPage />,
  },
  {
    name: 'items',
    title: 'Items',
    path: urlUtils.items.main(),
    element: <ItemsPage />,
  },
  {
    name: 'items-edit',
    title: 'Modifica Item',
    path: urlUtils.items.edit(),
    element: <ItemEditPage />,
  },
  {
    name: 'items-detail-edit',
    title: 'Items',
    path: urlUtils.items.editDetails(),
    element: <ItemDetailEditPage />,
  },
  {
    name: 'items-detail',
    title: 'Dettagli Item',
    path: urlUtils.items.details(),
    element: <ItemDetailsPage />,
  },
  {
    name: 'coupons',
    title: 'Coupon',
    path: urlUtils.coupons.main(),
    element: <CouponsPage />,
  },
  {
    name: 'coupons-detail',
    title: 'Dettagli Coupon',
    path: urlUtils.coupons.detail(),
    element: <CouponDetailPage />,
  },
  {
    name: 'locations',
    title: 'Località',
    path: urlUtils.locations.main(),
    element: <LocationsPage />,
  },
  {
    name: 'locations-detail',
    title: 'Dettagli Località',
    path: urlUtils.locations.detail(),
    element: <LocationDetailPage />,
  },
  {
    name: 'home',
    title: 'Home',
    path: urlUtils.main(),
    element: <HomePage />,
  },
];

export const allRoutes: any[] = [...unauth, ...auth];
