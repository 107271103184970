import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import urlUtils from 'utilities/urlUtils';
import Layout from 'components/layout';
import { auth, unauth } from 'router';
import useAuthStore from 'store/authStore';

function App() {
  const { isLogged } = useAuthStore();
  const userIsLogged = isLogged();
  return (
    <Routes>
      {auth.map((r) => (
        <Route key={r.name} path={r.path} element={userIsLogged ? <Layout>{r.element}</Layout> : <Navigate to={urlUtils.login()} />} />
      ))}
      {unauth.map((r) => (
        <Route key={r.name} path={r.path} element={!userIsLogged ? r.element : <Navigate to={urlUtils.main()} />} />
      ))}
      <Route path="*" element={<Navigate to={urlUtils.main()} />} />
    </Routes>
  );
}

export default App;
