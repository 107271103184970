import React, { ReactNode, useEffect, useState } from 'react';
import { PaginationElementType, TableColumnType } from 'models/table';
import TableColumns from './TableColumns';
import TableRow from './TableRow';
import TableFooter from './TableFooter';
import { Table as BoostrapTable } from 'react-bootstrap';

interface TableProps<T> {
  columns: TableColumnType<T>[];
  rows: T[];
  rowClickHandler?: ((row: T, rIdx: number) => void) | undefined;
  totalCount: number;
  onPageChanged: (limit: number, offset: number) => any;
  key?: string;
  isPaginated?: boolean;
}

function Table<T = any>({ columns, rows, rowClickHandler, totalCount, onPageChanged, key = 'id', isPaginated = true }: TableProps<T>) {
  const [tablePage, setTablePage] = useState<PaginationElementType>({ limit: 5, offset: 0, q: '' });

  useEffect(() => {
    pageChanged(tablePage.limit, tablePage.offset);
  }, []);

  const pageChanged = (limit: number, offset: number, q = '') => {
    setTablePage({ limit, offset, q });
    onPageChanged(limit, offset);
  };

  return (
    <div>
      <BoostrapTable striped>
        <thead>
          <TableColumns columns={columns} />
        </thead>
        <tbody>
          {rows != null &&
            rows.map((r, idx) => (
              <TableRow<T> key={(r as any)[key]} rKey={(r as any)[key]} columns={columns} row={r} rowClickHandler={rowClickHandler} rIdx={idx} />
            ))}
        </tbody>
      </BoostrapTable>
      {isPaginated && <TableFooter limit={tablePage.limit} offset={tablePage.offset} totalCount={totalCount} onPageChanged={pageChanged} />}
    </div>
  );
}

export default Table;
