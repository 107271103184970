import MenuElem from './MenuElem';

type PropsType = {
  title?: string;
  children: React.ReactNode;
};

export default ({ title, children }: PropsType) => {
  return (
    <li>
      {title != null ? <div className="menu-elem-group-title">{title}</div> : null}
      <hr />

      <ul>{children}</ul>
    </li>
  );
};
